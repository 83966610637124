<template>
    <main-layout
        :hidereport="true"
        :classes="'page_support'"
    >
        <template #content>
            <header-content :header-info="headerInfo" />
            <div class="wrapper">
                <div class="helpcenter-row">
                    <support-ticket-form />
                </div>
            </div>
            <confirm-modal ref="confirm-modal" />
        </template>
    </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout';
import SupportTicketForm from '@/components/support/SupportTicketForm';
import ConfirmModal from '@/components/modals/ConfirmModal';
import HeaderContent from '@/components/HeaderContent';

export default {
    components: {
        MainLayout,
        SupportTicketForm,
        ConfirmModal,
        HeaderContent
    },
    props: {
        propOrderId: {
            type: String
        }
    },
    data() {
        return {
            headerInfo: {
                title: 'Help Center',
                className: 'header-content__credits',
                // balance: 73.05,
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Dashboard',
                        url: '/cp/account'
                    }
                ]
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        window.localStorage.setItem('location_before_support', from.path)
        next();
    }
}
</script>
